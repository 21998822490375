<template>
   <div class="about space-y-6">
    <h1 class="mb-5 text-5xl font-bold text-center " >Cookie Policy</h1>

    <div class="space-y-6 pl-20 pr-20 pb-20">
        <b>Introduction</b>
            <p>
                This website uses cookies to collect anonymized data aimed at enhancing your user experience.
            </p>
                <b>What are cookies</b>
            <p>
                Cookies are small and temporary text files saved to your computer’s hard drive and managed through your internet browser.
            </p>
                <b>The cookies we use</b>
            <p>
                We use Google Analytics cookies, which transmit website traffic data to Google who then provides us with reports about your usage of the site.
            </p>
               <b>Your privacy</b>
            <p>
                Cookies do not collect any information about your identity as an individual.
            </p>
            <b>How to disable cookies</b>
            <p>
                You can disable cookies by going to your browser’s privacy settings and select the appropriate option.
            </p>
            <b>More information</b>
            <p>
                You can learn more about Google Analytics cookies by
                <a target="_blank" rel="noopener" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">visiting the following link</a>:
                This policy was updated on 2nd June 2018
            </p>
    </div>
  </div>
</template>

<script>

export default{
  name: 'Cookie Policy',
  components: {

  }
}
</script>
