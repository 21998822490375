<template>
  <div class="hero min-h-screen">
    <div class="text-center hero-content">
      <div class="max-w-md">
        <h1 class="mb-5 text-5xl font-bold">
              It Went Wrong!
            </h1> 
        <p class="mb-5">
              We are sorry to say that it looks like there was an error with sending your message, please try again
              <router-link to="/">
                <button  class="btn btn-primary">Back to form</button>
              </router-link>
            </p> 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactSubmissionFail"
};
</script>