import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import TermsOfService from '../views/TermsOfService.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import CookiePolicy from '../views/CookiePolicy.vue'
import ContactSubmissionSuccess from '../views/ContactSubmissionSuccess.vue'
import ContactSubmissionFail from '../views/ContactSubmissionFail.vue'
import Contact from '../views/Contact.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/terms-of-service',
    name: 'Terms of Service',
    component: TermsOfService
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy
  },
  {
    path: '/cookie-policy',
    name: 'Cookie Policy',
    component: CookiePolicy
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/contact-success",
    name: "Contact success",
    component: ContactSubmissionSuccess
  },
  {
    path: "/contact-fail",
    name: "Contact fail",
    component: ContactSubmissionFail
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
