<template>
    <div class="navbar mb-2 shadow-lg bg-neutral sticky top-0 z-50">
    <div class="flex-1 px-2 mx-2">
        <div class="dropdown">
        <div tabindex="0" class="m-1 btn">Switch Theme</div> 
        <ul tabindex="0" class="p-2 shadow menu dropdown-content bg-base-100 w-52">
        <li  class="text-lg font-bold" v-for="theme in themes" :key="theme" :data-set-theme="theme" data-act-class="ACTIVECLASS">{{theme}}
        </li>
        </ul>
        </div>
        <div class="flex-1 px-2 mx-2">
          <div class="items-stretch hidden lg:flex">
            <a class="btn btn-ghost btn-sm rounded-btn">
                    <router-link to="/">Home</router-link>
                  </a> 
            <a class="btn btn-ghost btn-sm rounded-btn">
                   <router-link to="/contact">Contact</router-link>
                  </a> 
          </div>
        </div> 
    </div> 
    </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      themes : [
        "light",  "dark",  "cyberpunk",  "aqua",
        // "light",  "dark","cupcake", "bumblebee",   "emerald", "corporate",   "synthwave",   "retro",   "cyberpunk",   "valentine",   "halloween",   "garden",  "forest",  "aqua","lofi","pastel",  "fantasy", "wireframe",   "black",   "luxury",  "dracula"
        ]
    }
  },
  props: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
