<template>
    <div>
    <Navbar/>
    <notifications />
   <router-view/>
   <Footer/>
  </div>
</template>
<script>
import Footer from './components/website/Footer.vue'
import Navbar from './components/website/Navbar.vue'

const myMixin ={
    methods: {
      capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),
      /**
         * https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
         * @param a
         */
      getOnlyUniqueValuesFromArray: function (a) {
        var seen = {};
        var out = [];
        var len = a.length;
        var j = 0;
        for(var i = 0; i < len; i++) {
             var item = a[i];
             if(seen[item] !== 1) {
                   seen[item] = 1;
                   out[j++] = item;
             }
        }
        return out;
      },
      /**
         * @param array - array to search
         * @param value - value to find
         */
      getFromArrayByValue: function (array, value) {
        for(let i = 0; i < array.length; i++) {
             if(array[i].value===value) {
                return array[i];
             }
        }
        return null;
      },
        /**
         * @param array - array to search
         * @param Id - Id to find
         */
      getFromArrayById: function (array, Id) {
        for(let i = 0; i < array.length; i++) {
             if(array[i].id===Id) {
                return array[i];
             }
        }
        return null;
      },
        removeTrailingZeros: function (string)
        {
           return parseFloat(string).toString();
        },
      arrayHasValues: function(array) {
          return Array.isArray(array) && array.length !== 0;
      },
      domDecoder: function (str) {
          let parser = new DOMParser();
        let dom = parser.parseFromString('<!doctype html><body>' + str, 'text/html');
        return dom.body.textContent;
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
    /**
     * https://stackoverflow.com/questions/3954438/how-to-remove-item-from-array-by-value
     * @param array
     * @param item
     */
      removeValueFromArray:  function(array, item) {
          var index = array.indexOf(item);
          if (index !== -1) array.splice(index, 1);
          return array;
      }
    }
  };

export default {
  name: 'App',
  mixins: [myMixin],
 components: {
     Navbar,
     Footer,
  }
}
</script>
<style>

</style>