<template>
 <div>
  <form
    name="ask-question"
    method="post"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    >
    <input type="hidden" name="form-name" value="ask-question" />
        <div class="p-10 card bg-base-200 mb-12 mt-10">
        <div class="form-control">
            <h1 class="mb-5 text-5xl font-bold text-center">
              Contact us!
            </h1> 
            <p class="mb-5 text-center">
              You can use the form below to reach out to us
            </p> 
          <label class="label">
            <span class="label-text">Your Email</span>
          </label> 
          <input name="email" type="text" class="input" @input="ev => form.email = ev.target.value"
             placeholder="The Email you want us to reply to">
          <label class="label">
            <span class="label-text">Your Message</span>
          </label> 
          <textarea class="textarea h-24 mb-5" ref="input"
          name="question"
          @input="ev => form.question = ev.target.value"
          placeholder="Enter your Message Here"></textarea>
        </div>
        <button class="btn btn-primary" type="submit">Submit</button>
        </div>
  </form>
  </div>
</template>

<script>

export default{
  name: 'Contact',
  methods: {
    updatePanelist (ev) {
      this.currentPanelist = ev.target.value
    }
  },
  data () {
    return {
      
    }
  },
  handleSubmit () {
       fetch("/contact", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": "ask-question",
          ...this.form
        })
      })
      .then(() => {
        this.$router.push('Contact success')
      })
      .catch(() => {
        this.$router.push('Contact fail')
      })
    }
}
</script>
