<template>
    <footer class="p-10 footer bg-base-200 text-base-content">
    <div>
        <img width="50" height="50" :src='BlackBadgerLabsLogo'>
        <p>© Black Badger Labs LLP 2014 - {{ new Date().getFullYear() }}
        </p>
    </div> 
    <div>
        <a class="link link-hover"><router-link to="/contact">Contact</router-link></a> 
    </div> 
    <div>
        <a class="link link-hover">
            <router-link to="/terms-of-service">Terms of Service</router-link>
        </a> 
    </div> 
    <div>
        <a class="link link-hover">
            <router-link to="/privacy-policy">Privacy Policy</router-link>
        </a> 
        <a class="link link-hover">
            <router-link to="/cookie-policy">Cookie Policy</router-link>
        </a>
    </div>
    </footer>
</template>

<script>
import BlackBadgerLabsLogo from '../../assets/full_logo.svg' 
export default {
  name: 'Navbar',
  data() {
    return {
      BlackBadgerLabsLogo: BlackBadgerLabsLogo
    }
  },
  props: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
