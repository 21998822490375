<template>
  
  <div class="about space-y-6">
    <h1 class="mb-5 text-5xl font-bold text-center " >Black Badger Labs Terms of Service</h1>

    <div class="space-y-6 pl-20 pr-20 pb-20">
      <p >The present terms and conditions shall govern your use of Black Badger Labs\' website (\'the website\') and the relationship between you and Black Badger Labs LLP (Black Badger Labs). By using the website you agree to the present terms of service.</p>
    
      <p >This website is not intended for the use of persons under the age of 13 years old.</p>
          
      <p >You notably accept the use of cookies as essential to the proper functioning of the website. Cookies are small files saved to your computer’s hard drive that track, save and store information about your interactions and usage of the website to provide you with a tailored experience.</p>
          
      <p >The website and all of its content are subject to copyright and trademark protection owned by Black Badger Labs who shall not be liable for any claim or damage arising from or in connection with the use of the website. The website is provided without warranty of any kind and Black Badger Labs may suspend or delete your account at their own discretion and without prior notification.</p>
          
      <p >You may choose to delete your account, in which case all public and private messages sent by you and your email address will be deleted from the live site. However we do keep backup copes of all data for a reasonable period of time. If you have any issues with data protection you can contact us using the contact form at blackbadgerlabs.com.</p>
          
      <p >When you do enter data on this site, you grant Black Badger Labs LLP a non-exclusive, sub-licenseable, transferable, royalty-free, worldwide licence to use any intellectual property content you submit on or in connection with this site.</p>

      <p >You also warrant that any data supplied to the site does not violate or infringe upon the rights of any third party including trademark, copyright, or any other personal or property rights, and you indemnify Black Badger Labs from any legal fees, damages or other expenses resulting from your breach of this warranty.</p>

      <p>The present terms and conditions may be updated in the future, when any significant changes are made, the updated terms will be shown to you when you log into the site, and you must agree to the revised terms in order to continue using the site.</p>

    </div>
  </div>
  
</template>

<script>

export default{
  name: 'Terms of Service',
  components: {

  }
}
</script>
