<template>
  <div class="max-w-sm pb-4">
    <a :href="project.link" target="_blank">
      <h3 class="text-left">{{project.name}}</h3>
      <div>
        <div class="">
           <video :id="'showcase-video-'+item_index" autoplay loop muted playsinline >
            <source :src="project.video_mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video> 
        </div>
      </div>
    </a>
  </div>
</template>


<script>
  export default {
    data() {
      return {
        
      };
    },
    components: {

     
    },
    methods: {
     
    },
    props: ['project','item_index'],
    mounted: function(){
    // var vid = document.getElementById("showcase-video-"+this.item_index);
    // vid.playbackRate = 0.5;
    }
  };
</script>

<style>

  /* .project-showcase-title{
    z-index: 1;
    left: 50%;
    top: 20%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: rgba(0,0,0,0.75);
    color: white;
  }
  
  video {
    width: 100%;
    object-fit: fill;
    height: 300px;
    object-position: 50% 50%;
} */

img, video {
    max-height: 200px;
}

</style>
