<template>
  <div class="pt-2 pb-4">
    <h3 class="text-2xl text-left pb-2">Some of our Previous Projects:</h3>
    <div class="grid grid-cols-3 gap-4">
    <project_showcase v-for="(project, index) in projects" :key="index" v-bind:item_index="index" v-bind:project="project"></project_showcase>
    </div>
  </div>
</template>


<script>
  import project_showcase from './ProjectShowcase.vue';

  export default {
    data() {
      return {
        projects:[
          {
            name: "Recipe Search",
           // link: "/recipe-search",
            video_mp4: "video/recipe_search.m4v",
          },
          {
            name: "Survive the Dark Ages",
            //link: "https://survivethedarkages.com",
            video_mp4: "video/survive_the_dark_ages_2.m4v",
          },
          {
            name: "Interactive Currency Map",
           // link: "/currency-map",
            video_mp4: "video/currency_map_recording_2.m4v",
          },
          {
            name: "Touch Type Fun",
           // link: "/touch-type-fun",
            video_mp4: "video/touch_type_fun_2.m4v",
          },
          {
            name: "Colour Swatch Pad",
          //  link: "/colour-swatch-pad",
            video_mp4: "video/colour_swatch_pad_2.m4v",
          },
          {
            name: "Crack The Code",
           // link: "/crack-the-code",
            video_mp4: "video/crack_the_code.m4v",
          },
          {
            name: "Catch Them",
          //  link: "/catch-them",
            video_mp4: "video/catch_them.m4v",
          },
          {
            name: "Bamboo Chat",
          //  link: "/bamboo-chat",
            video_mp4: "video/bamboo_chat.m4v",
          },
        ],
      };
    },
    components: {
      project_showcase,
    },
    methods: {
     
    },
    mounted: function(){
     console.log("test loaded");
    }
  };
</script>

<style>

 
</style>
