<template>
  <div class="home">
    <div class="hero min-h-screen">
    <div class="text-center hero-content">
      <div class="">
        <h1 class="mb-5 text-5xl font-bold">
              Welcome!
            </h1> 
            <div class="text-left">
              <p class="mb-5">
                Welcome to the Black Badger Labs Website
              </p> 

              <p class="pb-6">Black Badger Labs is a partnership based in the UK that develops bespoke software for the web.</p>

              <h3 class="text-2xl pb-6">Crafting Great User Experiences</h3>
              <p>We have built a number of different applications including online multiplayer games and various map based projects and productivity tools.</p>
            </div>
            <home-projects-showcase></home-projects-showcase>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Home from '.././components/website/Home';
import HomeProjectsShowcase from '.././components/website/HomeProjectsShowcase';
// import ProjectShowcase from '.././components/website/ProjectShowcase';


export default {
  name: 'Home',
  components: {
    HomeProjectsShowcase
  }
}
</script>
