<template>
  <div class="about space-y-6">
    <h1 class="mb-5 text-5xl font-bold text-center " >Privacy Policy</h1>

    <div class="space-y-6 pl-20 pr-20 pb-20">
            <p>
                This privacy policy is to inform you, as a user of Black Badger Labs’ services, games and applications, about information relevant to your privacy and personal data.

                The processing of personal data is governed by a number of regulations and notably the General Data Protection Regulation, which applies in the United Kingdom (UK).

            </p>
                <h3 class="text-4xl">Who we are and how to contact us</h3>
            <p>
                We are Black Badger Labs LLP, a limited liability partnership registered in England. You can contact us at blackbadgerlabs<span></span>@gmail.com or https://blackbadgerlabs.com/contact/.
            </p>
                <h3 class="text-4xl">Your personal data and how we collect it</h3>
            <p>
                 Your personal data is any information that we may collect and that could help identify you as an individual. As a user of Black Badger Labs some of the data that you share with us may be considered as personal data:</p>
            
            <ul class="list-disc list-inside">
                <li>username and email address, used when logging in to one of our platforms;</li>
                <li>payment information used when purchasing a digital item;</li>
                <li>posts and messages you share when communicating with other users;</li>
                <li>data entered when you interact with the developers;</li>
                <li>data entered when using our contact form.</li>
            </ul>

               <h3 class="text-4xl">What we do with your data</h3>
            <p>
                The data you share with us is typically stored on our servers and solely used in relation with your account and events happening in the game(s).

Your email address is used to reset your password when you forget it or to respond to you when you contact us. Your payment information is used when you purchase an item so that it is credited to your account.

The data shared with the developers is used to improve your user experience.

            </p>
             <h3 class="text-4xl">Your responsibility</h3>
            <p>
                It is possible for you to play our games without sharing any other personal data than your email address.

Your username can be anything you want as long as it it unique. Likewise you can interact with other players without sharing any personal information.
            </p>
            <h3 class="text-4xl">How long we retain your personal data</h3>
            <p>
                The data used to play our games is retained until you delete your account. All the private messages and in-game chat messages you have sent are then automatically erased.

The data entered in Black Badger Labs contact form is deleted after your enquiry has been addressed.
            </p>
            <h3 class="text-4xl">Your rights</h3>
            <p>
                The UK General Data Protection Regulation grants you a number of rights in relation with your personal data which are detailed below.
            </p>
            <b>(1) Right to be informed</b>
            <p>
                Your right to be informed is fulfilled through the present privacy policy.
            </p>
            <b>(2) Right of access and to data portability</b>
            <p>
                The personal data as well as all public and private messages you sent using our multiplayer games are easily accessible to you through your account and can be retrieved and sent to you in a portable file within 30 days of your request. The personal data entered through our contact form, if kept, can be sent to you likewise on request.
            </p>
            <b>(3) Right of rectification</b>
            <p>
                If the personal data we hold is incorrect you can ask for it to be rectified.
            </p><b>(4) Right of erasure</b>
            <p>
                All the data related to your account, including posts and messages, is automatically erased upon the deletion of your account. Additionally you can choose at any time to have all of your public and private messages deleted without needing to delete your account.
            </p>
            <b>(5) Right to object and to restrict processing</b>
            <p>
                If you disapprove of the use we do of your data it is your right to object and ask for the data processing to be restricted.
            </p>
            <b>(6) Right to lodge a complaint</b>
            <p>
                You can lodge a complaint with the UK Information Commissioner's Office, Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF United Kingdom – https://ico.org.uk/global/contact-us/.
            </p>
            <h3 class="text-4xl">Our partners</h3>
            <p>
                We partner with Braintree (a PayPal Service) who process the payments in our store. All credit card data is stored securely by them, you can find more information on how they protect your data here: https://www.braintreepayments..

We also use Google analytics to process anonymised statistics of out website usage. Google Analytic Data is retained for 26 months.

            </p>

            <p>
                All of Black Badger Labs’ software, design and brands are subjects to copyright and/or trademarks protection owned by Black Badger Labs LLP  United Kingdom.
            </p>

    </div>
  </div>
</template>

<script>

export default{
  name: 'Privacy Policy',
  components: {
  }
}
</script>
