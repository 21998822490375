import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import Notifications from '@kyvg/vue3-notification'

// Library that allows the user to switch the website theme
import {themeChange} from "theme-change"

import router from './router'
themeChange()
createApp(App).use(router).use(Notifications).mount('#app')